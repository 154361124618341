<template>
  <div class="editor">
    <div id="e">
    </div>
  </div>
</template>

<script>
import E from "wangeditor";
// import { post } from "@/config/axios";
export default {
  name: "editor",
  data() {
    return {
      content: "",
      editor: null,
      info_: null,
    };
  },
  model: {
    prop: "desc",
    event: "change",
  },
  watch: {
    isClear(val) {
      // console.log(val)
      if (val) {
        this.editor.txt.clear();
        this.info_ = null;
      }
    },
    desc(value) {
      //value为编辑框输入的内容，这里我监听了一下值，当父组件调用得时候，如果给value赋值了，子组件将会显示父组件赋给的值
      if (value != this.editor.txt.html()) {
        this.editor.txt.html(this.desc);
      }
    },
  },
  props: {
    desc: {
      type: String,
      default: "",
    },
    //业务中我们经常会有添加操作和编辑操作，添加操作时，我们需清除上一操作留下的缓存
    isClear: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    initE() {
      this.editor = new E("#e");
      this.editor.config.zIndex = 1;
      this.editor.config.onchangeTimeout = 1000; // 单位 ms
      this.editor.config.uploadImgServer = `/uploadFile`; //axios的baseurl是api，之后是后端图片上传的接口！
      this.editor.config.uploadImgHeaders = {// 设置请求头
        Name: "noticeComment",
        Token: localStorage.getItem("token"), 
        Uid: localStorage.getItem("uid"), 
      };
      this.editor.config.uploadFileName = "file"; //初始设置为file
      this.editor.config.uploadFileName = "uploadfile"; // 你的服务器地址，如果是靠axios上传图片则填写的是你与后端商量好的filename
      //如果使用axios则下列函数不需修改
      this.editor.config.uploadImgHooks = {
        before: function (xhr, editor, files) {
          console.log(xhr);
          console.log(editor);
          console.log(files);
          // files = formData;
          // 图片上传之前触发
          // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，files 是选择的图片文件

          // 如果返回的结果是 {prevent: true, msg: 'xxxx'} 则表示用户放弃上传
          // return {
          //     prevent: true,
          //     msg: '放弃上传'
          // }
        },
        success: function (xhr, editor, result) {
          console.log(xhr);
          console.log(editor);
          console.log(result);
          // 图片上传并返回结果，图片插入成功之后触发
          // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
        },
        fail: function (xhr, editor, result) {
          console.log(xhr);
          console.log(editor);
          console.log(result);
          // 图片上传并返回结果，但图片插入错误时触发
          // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
        },
        error: function (xhr, editor) {
          console.log(xhr);
          console.log(editor);
          // 图片上传出错时触发
          // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
        },
        timeout: function (xhr, editor) {
          console.log(xhr);
          console.log(editor);

          // 图片上传超时时触发
          // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
        },

        // 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
        // （但是，服务器端返回的必须是一个 JSON 格式字符串！！！否则会报错）
        customInsert: function (insertImg, result, editor) {
          console.log(editor);
          // 图片上传并返回结果，自定义插入图片的事件（而不是编辑器自动插入图片！！！）
          // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果

          // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
          console.log(result);
          var url = result.data.path;
          insertImg(url);
          // result 必须是一个 JSON 格式字符串！！！否则报错
        },
      };

      this.editor.config.onchange = (html) => {
        //只要富文本框内容出现变化就触发此函数
        this.info_ = html; // 绑定当前富文本框的内容
        this.$emit("itemclick", this.info_); // 将内容this.info_同步到父组件中，
      };
      //富文本框的功能区设置
      this.editor.config.menus = [
        "head", // 标题
        "bold", // 粗体
        "fontSize", // 字号
        "fontName", // 字体
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "foreColor", // 文字颜色
        "backColor", // 背景颜色
        "link", // 插入链接
        "list", // 列表
        "justify", // 对齐方式
        "quote", // 引用
        "emoticon", // 表情
        "image", // 插入图片
        "table", // 表格
        "code", // 插入代码
        "undo", // 撤销
        "redo", // 重复
      ];
      this.editor.destroy();
      this.editor.create(); //创建editor
      this.editor.txt.html(this.desc); //富文本框内还需填入父组件传入的服务器回显的信息
    },
  },
  mounted() {
    this.initE();
  },
};
</script>